
ul.links-hover li {
  padding-bottom:5px;
  background: linear-gradient(theme(colors.secondary), theme(colors.secondary)) center bottom; 
  background-size: 0% 1px; 
  background-repeat:no-repeat; 
  transition: all .5s;
}

ul.links-hover li:hover {
  background-size: 100% 3px;
}
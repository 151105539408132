@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRoundedLight.eot');
    src: local('Grota Rounded Light'), local('GrotaRoundedLight'),
        url('GrotaRoundedLight.eot?#iefix') format('embedded-opentype'),
        url('GrotaRoundedLight.woff2') format('woff2'),
        url('GrotaRoundedLight.woff') format('woff'),
        url('GrotaRoundedLight.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRoundedBlack-Italic.eot');
    src: local('Grota Rounded Black Italic'), local('GrotaRoundedBlack-Italic'),
        url('GrotaRoundedBlack-Italic.eot?#iefix') format('embedded-opentype'),
        url('GrotaRoundedBlack-Italic.woff2') format('woff2'),
        url('GrotaRoundedBlack-Italic.woff') format('woff'),
        url('GrotaRoundedBlack-Italic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRoundedBold.eot');
    src: local('Grota Rounded Bold'), local('GrotaRoundedBold'),
        url('GrotaRoundedBold.eot?#iefix') format('embedded-opentype'),
        url('GrotaRoundedBold.woff2') format('woff2'),
        url('GrotaRoundedBold.woff') format('woff'),
        url('GrotaRoundedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRoundedThin-Italic.eot');
    src: local('Grota Rounded Thin Italic'), local('GrotaRoundedThin-Italic'),
        url('GrotaRoundedThin-Italic.eot?#iefix') format('embedded-opentype'),
        url('GrotaRoundedThin-Italic.woff2') format('woff2'),
        url('GrotaRoundedThin-Italic.woff') format('woff'),
        url('GrotaRoundedThin-Italic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRoundedBlack.eot');
    src: local('Grota Rounded Black'), local('GrotaRoundedBlack'),
        url('GrotaRoundedBlack.eot?#iefix') format('embedded-opentype'),
        url('GrotaRoundedBlack.woff2') format('woff2'),
        url('GrotaRoundedBlack.woff') format('woff'),
        url('GrotaRoundedBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRoundedExtraBold-Italic.eot');
    src: local('Grota Rounded ExtraBold Italic'), local('GrotaRoundedExtraBold-Italic'),
        url('GrotaRoundedExtraBold-Italic.eot?#iefix') format('embedded-opentype'),
        url('GrotaRoundedExtraBold-Italic.woff2') format('woff2'),
        url('GrotaRoundedExtraBold-Italic.woff') format('woff'),
        url('GrotaRoundedExtraBold-Italic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRounded-Italic.eot');
    src: local('Grota Rounded Italic'), local('GrotaRounded-Italic'),
        url('GrotaRounded-Italic.eot?#iefix') format('embedded-opentype'),
        url('GrotaRounded-Italic.woff2') format('woff2'),
        url('GrotaRounded-Italic.woff') format('woff'),
        url('GrotaRounded-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRoundedBold-Italic.eot');
    src: local('Grota Rounded Bold Italic'), local('GrotaRoundedBold-Italic'),
        url('GrotaRoundedBold-Italic.eot?#iefix') format('embedded-opentype'),
        url('GrotaRoundedBold-Italic.woff2') format('woff2'),
        url('GrotaRoundedBold-Italic.woff') format('woff'),
        url('GrotaRoundedBold-Italic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRoundedExtraBold.eot');
    src: local('Grota Rounded ExtraBold'), local('GrotaRoundedExtraBold'),
        url('GrotaRoundedExtraBold.eot?#iefix') format('embedded-opentype'),
        url('GrotaRoundedExtraBold.woff2') format('woff2'),
        url('GrotaRoundedExtraBold.woff') format('woff'),
        url('GrotaRoundedExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRoundedLight-Italic.eot');
    src: local('Grota Rounded Light Italic'), local('GrotaRoundedLight-Italic'),
        url('GrotaRoundedLight-Italic.eot?#iefix') format('embedded-opentype'),
        url('GrotaRoundedLight-Italic.woff2') format('woff2'),
        url('GrotaRoundedLight-Italic.woff') format('woff'),
        url('GrotaRoundedLight-Italic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRoundedThin.eot');
    src: local('Grota Rounded Thin'), local('GrotaRoundedThin'),
        url('GrotaRoundedThin.eot?#iefix') format('embedded-opentype'),
        url('GrotaRoundedThin.woff2') format('woff2'),
        url('GrotaRoundedThin.woff') format('woff'),
        url('GrotaRoundedThin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Grota Rounded';
    src: url('GrotaRounded.eot');
    src: local('Grota Rounded'), local('GrotaRounded'),
        url('GrotaRounded.eot?#iefix') format('embedded-opentype'),
        url('GrotaRounded.woff2') format('woff2'),
        url('GrotaRounded.woff') format('woff'),
        url('GrotaRounded.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

